<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('TechnicalSupportHelpTree')"
    @cancel="onCancel('TechnicalSupportHelpTree')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'TechnicalSupportHelpTreeEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'TechnicalSupportHelpTree',
      pageHeader: 'Редактирование дерева решений по тех. поддержке',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Дерево решений по тех. поддержке',
          route: { name: 'TechnicalSupportHelpTree' },
        },
        {
          text: 'Редактирование дерева решений по тех. поддержке',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      technicalSupportHelpTreeNodes: (state) =>
        state.common.technicalSupportHelpTreeNodes,
    }),

    fields() {
      return [
        {
          form: [
            { type: 'text', key: 'name', label: 'Текст варианта выбора' },
            {
              type: 'select',
              key: 'parentId',
              label: 'Родительский узел для этой записи',
              list: [
                { name: 'Не выбрано', value: null },
                ...this.technicalSupportHelpTreeNodes.data,
              ],
            },
            {
              type: 'checkbox',
              key: 'isConclusion',
              label: 'Является ли элемент решением',
            },
            {
              type: 'textarea',
              key: 'conclusion',
              label: 'Текст решения, если элемент является решением',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    const technicalSupportHelpTreeNodes = this.getCommonList({
      name: 'TechnicalSupportHelpTreeNodes',
    });
    const items = this.getItem({ name: this.controllerName, id: this.id })
      .then(() => {
        this.form = this.lodash.cloneDeep(this.item.data);
      })
      .finally(() => {
        this.isLoadingPage = false;
      });

    Promise.all([technicalSupportHelpTreeNodes, items]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
